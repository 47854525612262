import React, { useEffect } from 'react';

import withLightbox from '../Lightbox/settings';

import loadComponents from '../Loadable';

const H3 = loadComponents('h3');
const Button = loadComponents('button');
const LightboxWrapper = loadComponents('lightbox-wrapper');
const Inner = loadComponents('lightbox-inner');

export default withLightbox(function CtaModal({
  content,
  showLightbox,
  delay,
  openLightbox,
  closeLightbox,
}) {
  useEffect(() => {
    function initModal() {
      if (!localStorage.getItem('modalCookie')) {
        setTimeout(() => {
          openLightbox();
        }, delay);
      }
    }
    initModal();
  }, []);

  const closeModal = e => {
    closeLightbox(e);
    localStorage.setItem('modalCookie', true);
  };

  return (
    <LightboxWrapper showLightbox={showLightbox} closeLightbox={closeLightbox}>
      <Inner>
        <H3 margin="1.875rem">{content.title}</H3>
        <p>{content.text}</p>
        <Button to={content.buttonLink} onClick={closeModal} secondary>
          {content.buttonText}
        </Button>
      </Inner>
    </LightboxWrapper>
  );
});
